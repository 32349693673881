import React from "react"
// import {Link} from 'gatsby';

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageContentBlock from "../components/imageContentBlock/imageContentBlock"
import FullWidth from "../components/fullWidth/fullWidth"
// import DynamicImage from '../components/ui/dynamicImage/dynamicImage';
import ModalCombiner from "../components/modalCombiner/modalCombiner"
import ContactForm from "../components/contact/contact"
import FullWidthContent from "../components/fullWidthContent/fullWidthContent"

const PyntePuder = () => {
  return (
    <Layout noHeaderImage={true}>
      <SEO title="Møbelpolstring og ombetrækning i København og Frederiksberg" />

      <FullWidth
        starter="Starter"
        imagePosition="top"
        filename="after6+.jpg"
        alt="Ompolstrede gule lænestole."
      >
        <h1>Møbelpolstring i København</h1>
        <p>
          Jeg ompolstrer stole, lænestole, skamler og andre møbler uanset om det
          er danske klassikere eller antikke arvestykker. Når vi opstarter et
          projekt sammen, hjælper jeg dig med at finde de tekstiler eller læder,
          der matcher til dit møbel og din smag for boligindretning.
        </p>
        <ModalCombiner />
      </FullWidth>

      <ImageContentBlock
        imagePosition="right"
        filename="Office-leather.jpg"
        alt="Ompolsteret kontorstol i cognac farvet læder"
      >
        <h2>Ombetrækning i læder og tekstiler</h2>
      </ImageContentBlock>

      <ImageContentBlock
        imagePosition="left"
        filename="IMG_0617.JPG"
        alt="Lænestol polsteret i Grå Halland tekstil fra kvadrat"
      >
        <h2>Kvalitets materialer</h2>
      </ImageContentBlock>

      <FullWidth
        imagePosition="top"
        filename="the bluelounge2.jpg"
        alt="Blå loungestol"
      >
        <h2>Klassisk ombetrækning og møbelpolstring</h2>
        <p>
          Hvis du har et ældre møbel, der måske har været i familiens eje i
          mange år, renoverer og ombetrækker jeg, så møblet får ny liv igen. Jeg
          holder af de gammeldags teknikker og benytter, når det er muligt
          periodekorrekte materialer til at opnå det optimale resultat for dit
          møbel. Studio Gafni løser opgaver indenfor stole, spisebordsstole,
          lænestole, sofaer med videre - og hjælper gerne på opgaver i
          København, Nordhavn, Østerbro, Vesterbro, Indre By og Frederiksberg
          samt hele hovedstadsområdet.
        </p>
      </FullWidth>

      <ImageContentBlock
        imagePosition="right"
        filename="loungeafter1 (Medium) (Medium).jpeg"
        alt="Ompolsteret ældre loungestol"
      >
        <h2>Antikke møbler i nyt look</h2>
      </ImageContentBlock>

      <ImageContentBlock
        imagePosition="left"
        filename="the blueafter2.jpg"
        alt="Ompolsteret blå spisebordstol"
      >
        <h2>Stole ompolstres</h2>
      </ImageContentBlock>

      <FullWidthContent>
        <div
          style={{
            backgroundColor: "#F7F7F7",
            borderRadius: "10px",
            padding: "30px 0 30px 0",
          }}
        >
          <h2 style={{ textAlign: "center" }}>
            Kontakt mig for en snak, om ompolstring af dit møbel:
          </h2>
          <ModalCombiner />
        </div>
      </FullWidthContent>

      <FullWidth
        imagePosition="top"
        filename="blue velour2 (Medium).jpg"
        alt="Ompolsteret stol i blå velour"
      >
        <h2>Møbelpolstring af høj kvalitet</h2>
        <p>
          Jeg hjælper dig hele vejen fra valg af tekstiler, til du modtager det
          færdigrenoverede møbel - og sætter en ære i at levere både
          kvalitetshåndværk og give dig assistance på valg af materialer og
          farver til præcis dit hjem. Opgaver løses inden for både de moderne
          klassikere i dansk design eller antikke og ældre møbler.
        </p>
      </FullWidth>

      <FullWidth
        imagePosition="top"
        filename="3.jpg"
        alt="Ompolsteret lænestol i grå tekstil"
      >
        <h2>Ompolstring giver nyt liv til dit møbel</h2>
        <p>
          {/* Jeg hedder Atar Gafni og ejer Studio Gafni. Jeg har en baggrund i møbelpolstring samt industriel
					design og tilbyder derfor dig en værdifuld kombination og æstetisk sans for design kombineret med
					praktisk håndværk, når møblerne skal ompolstres.
					<br /> <br />
					Når vi starter en dialog op, omkring ompolstring af dit møbel eller en speciallavet pude, lægger jeg
					vægt på, vi tager en god snak om dine ønsker for opgaven og de materialer, der skal benyttes under
					arbejdet.
					<br /> <br /> */}
          Du er meget velkommen til at kontakte mig for en uforpligtende snak
          gennem kontaktformularen her:
        </p>
      </FullWidth>

      <FullWidthContent>
        <div style={{ maxWidth: "450px", margin: "0 auto" }}>
          <h2 style={{ textAlign: "left" }}>
            Kontakt mig helt uforpligtende her:
          </h2>
          <ContactForm />
        </div>
      </FullWidthContent>
    </Layout>
  )
}

export default PyntePuder
